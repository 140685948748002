<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined v-if="!showEval">
      <containerTitle title="Evaluation"></containerTitle>
      <v-card-text>
        <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="value.eval"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="result.stepType + '_' + result.stepID + '_eval'"
        ></lineFieldsArray>
      </v-card-text>
    </v-card>
    <explorerOnError v-model="localValue" :result="result"></explorerOnError>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import lineButtons from "@/cfg/lineButtons.json";
import containerTitle from "@/commonComponents/containerTitle.vue";

import options from "@/cfg/options.json";

export default {
  components: {lineFieldsArray, explorerOnError, containerTitle},
  data() {
    return {
      operatorList: [],
      rerunsObject: {},
      hideForTheseSteps: ["Explorer_cellular_data_connect", "Explorer_SMS", "Explorer_cellular_attach"]
    };
  },
  props: ["field", "fieldAttributes", "result", "value"],
  computed: {
    showEval() {
      return this.hideForTheseSteps.includes(this.result.stepType);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    fields() {
      let fields = {
        type: {
          type: "select",
          associatedOption: this.customOptions(options),
          name: "Type",
          fieldAttrInput: {class: "required"},
        },
        operator: {
          type: "select",
          associatedOption: options.pingEvalOperator,
          name: "Operator",
          fieldAttrInput: {class: "required"},
        },
        content: {
          type: "number",
          name: "Value",
          fieldAttrInput: {class: "required"},
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: {class: "required"},
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: {class: "required"},
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: {class: "", maxlength: '100'},
        },
        remove: lineButtons.remove,
      };
      if (this.result.stepType == "Explorer_USSD") { // static content!
        delete fields['type'];
        let staticField = {
          input: false,
          type: "string",
          name: "Type",
          string: "Content",
          colAttrs: {
            cols: 1
          },
        };
        let staticValue = {
          type: "hidden",
          default: "content",
          colAttrs: {class: "d-none"},
        }
        fields['content']['type'] = "text"; //it should be text
        fields = {staticField, "type": staticValue, ...fields};
      }
      return fields;
    },

  },
  methods: {
    customOptions(optionList) {
      let options = optionList.pingEvalType;
      if (this.result.stepType == "Explorer_TWAMP") {
        options = optionList.explorerTwampEvalType;
      }
      return options;
    },
    checkInput(value) {
      let localValue = {...this.value};
      this.$set(localValue, "eval", value);
      this.$emit("input", localValue);
    },
  },
};
</script>